/* eslint-disable */

var $translations = {};
$translations.Home = "Home";

// Calculators
$translations.CalculateAs = "Calculate as";
$translations.Length = "Comprimento";
$translations.Width = "Largura";
$translations.Calculate = "CALCULAR";
$translations.CALCULATE = $translations.Calculate;
$translations.Depth = "Profundidade";
$translations.GO = "GO";

$translations.Imperial = "Imperial";
$translations.Metric = "Métrico";

$translations.Yards = "jardas";
$translations.Feet = "pés";
$translations.Inches = "polegadas";

$translations.Metre = "metros";
$translations.Centimeters = "centímetros";


// Calculators - Concrete
$translations.Thickness = "Espessura";
$translations.Diameter = "Diâmetro";
$translations.Height = "Altura";
$translations.EstimatedConcreteRequired = "Concreto Estimado Requerido";

$translations.Flatwork = "Base";
$translations.Columns = "Colunas";
$translations.Footings = "Rodapé";

