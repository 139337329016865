/* =====================================================================
 * Vendedores
 * =====================================================================
 * 1. Request é feito na escolha do tipo de obra
 * 2. Com a resposta os estados são preenchidos
 * 3. De acordo com o estado escolhido, as cidades são carregadas
 * 4. Com todas as informações, a função getData é usada para exibir os dados do vendedor
 * ===================================================================*/

(function (doc) {

  const typeSelect = doc.getElementById('sellers-tipo');
  const stateSelect = doc.getElementById('sellers-estado');
  const citySelect = doc.getElementById('sellers-cidade');

  if (!typeSelect || !stateSelect || !citySelect) return;

  const getData = (data, estado, cidade) => {
    return data.filter(
      item =>  item.estado === estado && item.cidade === cidade
    );
  };

  const cleanSellers = () => {
    doc.querySelector('.sellers-list').classList.add('d-none');
    const sellers = doc.querySelectorAll('.seller-content');
    Array.prototype.forEach.call(sellers, (seller) => {
      seller.parentNode.removeChild(seller);
    });
  };

  const sendRequest = (type, action) => {
    cleanSellers();
    const request = new XMLHttpRequest();
    request.open('POST', ajaxurl, true);
    request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded;');
    request.onload = () => {
      const status = request.status;
      const response = request.response;
      if (status < 200 && status > 400) return;
      const data = JSON.parse(response);
      action(data);
    };

    request.send('action=getvendedores&tipo=' + type);
  };

  typeSelect.addEventListener('change', () => {
    const stateSelectFirstOpt = stateSelect.options[0];
    const stateSelectFirstOptText = stateSelectFirstOpt.innerHTML;

    stateSelect.disabled = true;
    stateSelectFirstOpt.innerHTML = 'Carregando...';
    stateSelect.selectedIndex = 0;

    citySelect.disabled = true;
    citySelect.selectedIndex = 0;

    const action = (data) => {
      stateSelect.innerHTML = '';
      stateSelect.appendChild(stateSelectFirstOpt);
      stateSelect.selectedIndex = 0;

      const dataStates = [];

      data.forEach(item => {
        if (dataStates.includes(item.estado)) return;
        dataStates.push(item.estado);
      });

      const statesName = {
        'AC' : 'Acre',
        'AL' : 'Alagoas',
        'AP' : 'Amapá',
        'AM' : 'Amazonas',
        'BA' : 'Bahia',
        'CE' : 'Ceará',
        'DF' : 'Distrito Federal',
        'ES' : 'Espírito Santo',
        'GO' : 'Goiás',
        'MA' : 'Maranhão',
        'MT' : 'Mato Grosso',
        'MS' : 'Mato Grosso do Sul',
        'MG' : 'Minas Gerais',
        'PA' : 'Pará',
        'PB' : 'Paraíba',
        'PR' : 'Paraná',
        'PE' : 'Pernambuco',
        'PI' : 'Piauí',
        'RJ' : 'Rio de Janeiro',
        'RN' : 'Rio Grande do Norte',
        'RS' : 'Rio Grande do Sul',
        'RO' : 'Rondônia',
        'RR' : 'Roraima',
        'SC' : 'Santa Catarina',
        'SP' : 'São Paulo',
        'SE' : 'Sergipe',
        'TO' : 'Tocantins'
      };

      dataStates.sort().forEach(state => {
        const option = doc.createElement('option');
        option.value = state;
        option.innerHTML = statesName[state];
        stateSelect.appendChild(option);
      });

      stateSelect.disabled = false;
      stateSelect.options[0].innerHTML = stateSelectFirstOptText;
    };

    sendRequest(typeSelect.value, action);
  });

  stateSelect.addEventListener('change', () => {
    const citySelectFirstOpt = citySelect.options[0];
    const citySelectFirstOptText = citySelectFirstOpt.innerHTML;

    citySelect.disabled = true;
    citySelectFirstOpt.innerHTML = 'Carregando...';
    citySelect.selectedIndex = 0;

    const action = (data) => {
      const citySelectFirstOpt = citySelect.options[0];
      citySelect.innerHTML = '';
      citySelect.disabled = true;
      citySelect.appendChild(citySelectFirstOpt);

      const dataCities = [];

      data.forEach(item => {
        if (item.estado != stateSelect.value) return;
        dataCities.push(item.cidade);
      });

      // const dataCities = data.filter(item => item.estado === stateSelect.value);

      dataCities.sort().forEach(city => {
        const option = doc.createElement('option');
        option.value = city;
        option.innerHTML = city;
        citySelect.appendChild(option);
      });

      citySelect.disabled = false;
      citySelect.options[0].innerHTML = citySelectFirstOptText;
    };

    sendRequest(typeSelect.value, action);
  });

  citySelect.addEventListener('change', () => {
    const sortPhones = (phones) => {
      if (!phones) return;
      phones = phones.split(',');
      const phoneList = (items) => `<ul class="seller-phones">${items}</ul>`;

      const listItems = [];
      phones.forEach(phone => {
        listItems.push(`
          <li>
            <a class="mobile-phone-seller" href="https://api.whatsapp.com/send?phone=55${phone.replace(/\D/g,'')}">
              <i class="fab fa-whatsapp"></i>
              ${phone}
            </a>
          </li>
        `);
      });

      return phoneList(listItems.join(''));
    };

    const action = (data) => {
      const sellers = getData(data, stateSelect.value, citySelect.value);
      const sellerContainer = doc.querySelector('.card.seller');
      const sellerHTML = (unidade, email, telefones) => `
      ${unidade ? `<h4 class="seller-title text-uppercase">UNIDADE ${unidade}</h4>` : ''}
      ${email ? `<p><strong>Email: ${email}</strong><br></p>` : ''}
      ${telefones ? `<strong>Telefone(s):</strong><br>${sortPhones(telefones)}` : ''}
      `;
      sellers.forEach(seller => {
        const dom = doc.createElement('div');
        dom.className = 'seller-content';
        dom.innerHTML = sellerHTML(seller.title, seller.email, seller.telefones);
        sellerContainer.insertBefore(dom, sellerContainer.childNodes[0]);
        const textContainer = sellerContainer.querySelector('.info strong');
        textContainer.innerHTML = !seller.email && !seller.telefones ? 'Ligue em nossa central de vendas e faça um orçamento:' : 'Se preferir, ligue em nossa central de vendas e faça um orçamento:';
      });

      doc.querySelector('.sellers-list').classList.remove('d-none');
    };
    sendRequest(typeSelect.value, action);
  });

}(document));
