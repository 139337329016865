import 'bootstrap';

(function(doc, jQuery){
  const btnCookie = doc.querySelector('.btn-cookie-consent');
  if (btnCookie) {
    btnCookie.addEventListener('click', () => {
      Cookiebot.renew();
    });
  }

  const app_carousel = jQuery('.app-carousel');
  const app_carousel_settings = {
    loop: false,
    nav: false,
    dots: false,
    URLhashListener: true,
    startPosition: 'URLHash',
    items: 1,
    center: false,
    margin: 30,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    onTranslate: beforeChangeSlide,
  };

  function initialize(){
    var container_width = jQuery('body').find('.navbar').outerWidth();
    if(container_width > 767) {
      app_carousel.owlCarousel(app_carousel_settings);
      app_carousel.addClass('owl-carousel');
      // app_carousel.trigger('refresh.owl.carousel');
    } else {
      app_carousel.trigger('destroy.owl.carousel').removeClass('owl-carousel owl-loaded');
      app_carousel.find('.owl-stage-outer').children().unwrap();
    }
  }


  function beforeChangeSlide(event){
    const nav_items = doc.querySelector( '.app-carousel-navigation' ).querySelectorAll('li');
    nav_items.forEach((item, index) => {
      item.classList.remove( 'active' );
      if ( index === event.item.index ) {
        item.classList.add( 'active' );
        return;
      }
    });
  }

  var id;
  jQuery(window).resize( function() {
    clearTimeout(id);
    id = setTimeout(initialize, 500);
  });

  initialize();

}(document, jQuery));
